import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import {
  Auth, SatCoreComponent, accommodationsManager, assignmentManager,
  classroomManager, contentManager, courseManager, dialogManager,
  exportManager, gradebookManager, i18nAddSatellite, libraryManager, 
  navigationManager, thirdPartyManager, topNavManager, userManager
} from 'sat-core';
import { API_ROOT, LESSON_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';

import iconCommentActiveYellow from './img/icon-comment-active-yellow.svg';
import logo from './img/simple-solutions-logo-sm.png';

import 'semantic-ui-less/semantic.less';
import './index.less';
import * as serviceWorker from './serviceWorker';

/* Put component override imports here */
import './views/MainViewOverride';
import './views/LoginOverride';
import './views/ProgressViewOverride';
import './views/TeacherDashboardOverride';
import './components/CourseListBannerOverride';
import './services/AccommodationsServiceOverride';
import './services/ItemBankServiceOverride';

import simpleSolutionsTranslations from './simpleSolutionsTranslations';

i18nAddSatellite('simpleSolutions', simpleSolutionsTranslations);

const App = SatCoreComponent('App');
Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo, LESSON_ROOT);

// accommodationsManager.setIncludeAssignmentAccommodations(true);
// accommodationsManager.setIncludeClassroomAccommodations(true);
assignmentManager.setEnableCourseSearch(true);
assignmentManager.setLateSubmissionOptionAllowed(false);
assignmentManager.setUseAssignmentTabRollovers(true);
classroomManager.setEnabledStudentsOnly(false);
classroomManager.setIncludeGroups(true);
contentManager.setFeedbackIconActiveImg(iconCommentActiveYellow);
contentManager.setResourceIFrameUrl('https://starhelp.simplesolutions.org');
courseManager.setAllowStudentBooks(false);
exportManager.setAllowExportFullGradebookItemAnalysis(false);
exportManager.setAllowExportFullGradebookSummary(false);
exportManager.setAllowExportMiniCourseReportPointsOnly(true);
exportManager.setAllowExportMiniCourseReportScoresOnly(true);
exportManager.setAllowExportMiniGradebookItemAnalysis(true);
exportManager.setAllowExportMiniGradebookSummary(true);
exportManager.setAllowExportMiniStandardsReportScoresOnly(true);
gradebookManager.setAllowAssignmentLink(false);
gradebookManager.setAllowGradebookStandards(true);
gradebookManager.setHideDetailsDates(true);
gradebookManager.setShowDetailsGradeAsPercentage(true);
gradebookManager.setShowDetailsGradeAsRawScore(true);
libraryManager.setAllowPrintItemBanking(true); // need to coordinate this with ItemBankServiceOverride.sortLibraryCardKebabOptions()
libraryManager.setAllowDigitalItemBanking(false); // need to coordinate this with ItemBankServiceOverride.sortLibraryCardKebabOptions()
thirdPartyManager.setAllowedThirdPartyChatLauncherUserRoles(['district_admin_user', 'school_admin_user', 'teacher_user']);
thirdPartyManager.setThirdPartyChatLauncherUrl('https://desk.zoho.com/portal/api/web/inapp/610667000005903015?orgId=751715531');
topNavManager.setShowFullNotificationBanner(true);
userManager.setAllowAdminTestBuilderView(false);
userManager.setAllowStudentUsernamesAsEmail(false);
userManager.setAllowTeacherLibraryContentView(false);
userManager.setAllowTeacherTestBuilderView(true);
userManager.setClientPrefix('SS');

ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    userManager={userManager}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
